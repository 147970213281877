import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LinkLocation } from '../mlva-types';

interface HomepageProps {
  location: LinkLocation;
}

export const Homepage = ({ location }: HomepageProps) => {
  const content = useStaticQuery(graphql`
    query HomepageSection {
      markdownRemark(frontmatter: { templateKey: { eq: "homepage-section" } }) {
        frontmatter {
          bottomBackground {
            childImageSharp {
              gatsbyImageData
            }
          }
          topBackground {
            childImageSharp {
              gatsbyImageData
            }
          }
          logo {
            publicURL
          }
        }
      }
    }
  `).markdownRemark.frontmatter;

  const language = location?.state?.language;

  const logoCenteredContainer = (
    <div className="container has-text-centered">
      <figure className="image is-inline-block">
        <Link to="/about" state={{ language: language }}>
          <img
            src={content.logo?.publicURL}
            alt="mlva studio logo"
            id="homepage-logo"
          />
        </Link>
      </figure>
    </div>
  );

  const topBanner = (
    <section className="homepage section hero is-halfheight">
      <div className="hero-body">{logoCenteredContainer}</div>
    </section>
  );

  const topBackgroundImage =
    content.topBackground && getImage(content.topBackground);
  const bottomBackgroundImage =
    content.bottomBackground && getImage(content.bottomBackground);

  const topBannerWithBackground = topBackgroundImage && (
    <section
      className="homepage section hero is-halfheight has-background"
      id="homepage-work-section"
    >
      <GatsbyImage
        image={topBackgroundImage}
        alt=""
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        objectFit="cover"
        objectPosition="50% 50%"
      />
      <div className="hero-body">{logoCenteredContainer}</div>
    </section>
  );

  const bottomBannerWithBackground = (
    <section
      className="homepage section hero is-halfheight has-background"
      id="homepage-work-section"
    >
      <GatsbyImage
        image={bottomBackgroundImage}
        alt=""
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        objectFit="cover"
        objectPosition="50% 50%"
      />
      <Link to="/work" state={{ language: language }}>
        <h2
          className="work-title has-text-white has-text-bold has-text-centered"
          id="homepage-work-text"
        >
          WORK
        </h2>
      </Link>
      <div id="homepage-bottom-overlay" />
    </section>
  );

  return (
    <div className="is-fullheight is-unselectable">
      {topBackgroundImage ? topBannerWithBackground : topBanner}
      {bottomBannerWithBackground}
    </div>
  );
};
